<template>
  <div class="category-container">
    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />

    <section>
      <div class="container-fluid">
        <h1 class="header">
          {{ title }}
        </h1>
      </div>

      <div
        v-show="!is_loading"
        class="cards-container"
        :class="{
          [item_image_shape]: item_image_shape,
          [dataHead.size]: dataHead.size,
        }"
        :style="'justify-content: ' + align + ';'"
      >
        <div
          v-for="(item, i) in items"
          :key="item.id"
          :class="{ 'store-item': followers }"
        >
          <carousel-item
            :item="item"
            :item-type="item_image_shape"
            :size="dataHead.size"
            :area="dataHead.dtype === 'promoted' ? dataHead: null"
            :position="i"
            @slide-click="onItemClick(item, i, $event)"
          />
        </div>
      </div>
    </section>

    <div v-show="is_loading" class="loader-container">
      <loader color="#798B96" />
    </div>

    <div v-if="!is_loading && items.length < 1" class="loader-container">
      <p>No items left</p>
    </div>

    <div class="pagination-container">
      <v-pagination
        v-if="pagination"
        v-model="page"
        :length="paginations_count"
      />
    </div>
  </div>
</template>

<script>
  import { amsClient } from '@/service/ams';
  import CarouselItem from '@/components/swiper-carousel/carousel-item/carousel-item';
  import { matchString } from '@/utils/matchString';
  import Auth from '@/service/authService';
  import Loader from '@/components/loader/loader';
  import rtm from '@/service/realTimeMessages';
  import { debounce } from 'lodash';
  import normaliseMetaTags from '@/utils/normaliseMetaTags';
  import { performProductsMinPrice } from '@/utils/product-pricelist';
  import {
    LARGE_PRODUCTS_CAROUSEL_ITEMS_CONFIG,
    MEDIUM_PRODUCTS_CAROUSEL_ITEMS_CONFIG,
    SMALL_PRODUCTS_CAROUSEL_ITEMS_CONFIG,
  } from '@/config/size-carousel.config';

  export default {
    name: 'CategoryPage',
    components: {
      CarouselItem,
      Loader,
    },
    data() {
      return {
        pagination: false,
        page: 1,
        paginations_count: 2,
        is_loading: true,
        add_new_pagination_page: true,
        default_link: '',
        items: [],
        title: 'Items',
        item_image_shape: 'horizontal',
        aspect_ratio: '1x1',
        url: '',
        dataHead: {},
        metadata: {},
        viewWidth: 0,
      };
    },
    computed: {
      getVideosList() {
        return this.$store.getters['wishlist/getVideosList'];
      },
      followers() {
        return this.$route.query.title === 'Followed stores';
      },
      align() {
        if (this.dataHead?.itype === 'section_shopitem_shoprz' || this.dataHead?.itype === 'section_shoprz') {
          let breakpoints = SMALL_PRODUCTS_CAROUSEL_ITEMS_CONFIG.breakpoints;

          if (this.dataHead.size === 'medium') {
            breakpoints = MEDIUM_PRODUCTS_CAROUSEL_ITEMS_CONFIG.breakpoints;
          } else if (this.dataHead.size === 'large') {
            breakpoints = LARGE_PRODUCTS_CAROUSEL_ITEMS_CONFIG.breakpoints;
          }

          const breakPointsKeys = Object.keys(breakpoints).sort((a, b) => b - a).map(Number);
          const viewBreakpoint = breakPointsKeys.find((key) => this.viewWidth >= key);
          const nitems = breakpoints[viewBreakpoint]?.slidesPerView;

          return this.items.length < nitems ? this.dataHead?.align : 'center';
        }
        return 'center';
      },
    },
    watch: {
      page: async function (newValue) {
        this.is_loading = true;
        const url =
          this.default_link + `?start=${(newValue - 1) * 20}&limit=20`;
        this.url = url;

        await this.getItems(url);

        if (
          this.add_new_pagination_page &&
          this.paginations_count === newValue
        ) {
          this.paginations_count++;
        }

        this.is_loading = false;
      },

      getVideosList: {
        handler(items) {
          const isWatchLater = this.default_link.includes('watch_later');
          const isRecent = this.default_link.includes('recent');

          if (Auth.get('user').guest && (isWatchLater || isRecent)) {
            this.$router.push('/');
          }

          if (isWatchLater) {
            this.items = Object.values(items);
          }
        },
        deep: true,
      },
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.loading = true;

        if (vm.isRealtimeItems(to.query?.link) && !vm.$rtmWasInited) {
          return;
        }

        vm.getData(to);
      });
    },
    beforeRouteUpdate(to, from, next) {
      this.loading = true;
      this.getData(to);
      next();
    },

    mounted() {
      this.checkWidth();
      this.$bus.$on('resizeWindow', this.checkWidth);
      const { title } = this.$route.query;
      // if (title) {
      //   this.metadata.title = title;
      // }

      this.$bus.$on('toggle:heart', this.removeWishList);

      if (this.isRealtimeItems(this.$route.query?.link)) {
        this.rtmSubscription = rtm.on(
          ['started', 'on_air_now', 'finished'],
          debounce(() => {
            this.getData(this.$route);
          }, 1000),
        );
      }
    },
    beforeDestroy() {
      rtm.off(this.rtmSubscription);
    },
    methods: {
      checkWidth() {
        this.viewWidth = window.innerWidth;
      },
      async onItemClick(item, index, $event) {
        if (this.dataHead.dtype === 'promoted') {
          return;
        }

        const newItem = { ...item };

        if (newItem?.itype?.includes('vod')) {

          //todo: position
          this.$router.push( `/s/${newItem.storefront?.slug}/v/${newItem.slug || newItem.id}`);
          // this.$router.push(`/v/${newItem.slug || newItem.id}`);
          // this.$bus.$emit('togglePopupVOD', {
          //   item: {
          //     ...newItem,
          //     ...(!newItem.share && {
          //       share: `/s/${newItem.creator.slug}/v/${newItem.slug}/`,
          //     }),
          //   },
          //   options: {
          //     startPosition: ($event?.time_in || 0) / 1000,
          //   },
          // });
        }
      },
      calculateWidth() {
        this.windowSize = window.innerWidth;
      },
      isRealtimeItems(link) {
        return link && matchString(link, ['/on_air/', '/coming_soon/']);
      },
      async getItems(url = this.url) {
        console.log('url', url);
        await amsClient.callAms(url).then((data) => {
          this.items = data.items;
          this.add_new_pagination_page = !!data.head.more_items_link;
        });
      },
      removeWishList(id) {
        this.items = this.items.filter((item) => item.id !== id);
      },
      switchCardShape(data) {
        const EVENTS_URL = '/on_air/';
        const STORES_URL = '/shoprzapi/';
        const RECENT_WATCH_LATER_URL = '/stk/get/watch_later';
        const RECENT_PRODUCTS_URL = '/stk/get/';
        const OTHER_PRODUCTS_URL = '/filter/shop_product/';

        if (
          this.default_link.includes(EVENTS_URL) ||
          this.default_link.includes(RECENT_WATCH_LATER_URL) ||
          this.default_link.includes('recent/')
        ) {
          this.aspect_ratio = '16x9';
          this.item_image_shape = 'horizontal';
        } else if (this.default_link.includes(STORES_URL)) {
          this.aspect_ratio = '1x1';
          this.item_image_shape = 'circle';
        } else if (
          matchString(this.default_link, [
            RECENT_PRODUCTS_URL,
            OTHER_PRODUCTS_URL,
          ])
        ) {
          this.aspect_ratio = '1x1';
          this.item_image_shape = 'vertical';
        } else {
          this.aspect_ratio = data.head.aspect_ratio;
          this.item_image_shape = data.head.item_image_shape;
        }
      },
      async getData(route) {
        this.is_loading = true;

        this.default_link = route.query?.link;
        // this.default_link = route.query ? route.query?.link + `${route.query?.reflink ? '&reflink=' + route.query.reflink : ''}` : '';

        if (this.isRealtimeItems(this.default_link) && this.$eventsTimestamp) {
          this.default_link = this.default_link + '&t=' + this.$eventsTimestamp;

          if (this.default_link.includes('coming_soon/')) {
            this.default_link = route.query
              ? this.default_link +
                `${
                  route.query?.reflink ? '&reflink=' + route.query.reflink : ''
                }`
              : this.default_link;
          }
        }

        await amsClient.callAms(this.default_link).then(async (data) => {
          const metadata = normaliseMetaTags(data.head);
          this.metadata = { ...metadata, ...this.metadata };

          this.dataHead = data.head;

          if (
            data.head.model === 'departments' ||
            data.head.model === 'store_channels'
          ) {
            this.items = data.items;
          } else if (
            data.head.itype === 'section_filters_shoprz' &&
            data.head.title != 'Uploads'
          ) {
            this.pagination = true;
            console.log('this.items 2: ', data);

            await this.getItems(data.head.more_items_link);
          } else {
            if (this.default_link.includes('coming_soon/')) {
              this.items = data.items.filter((item) => !item.is_live);
            } else if (data.head.itype === 'section_shopitem_shoprz') {
              // TODO: only for POC
              this.items = performProductsMinPrice(data.items);
            } else {
              this.items = data.items;
            }
          }

          this.title = route.query?.title.includes('^*^')
            ? route.query?.title.replaceAll('^*^', '&')
            : route.query?.title;

          this.switchCardShape(data);
        });

        this.is_loading = false;
      },
    },
  };
</script>
<style scoped lang="scss">
  .header {
    margin-top: 30px;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }

  .category-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 260px));
    justify-content: center;
    gap: 16px;
    height: 100%;
    padding-right: 15px;
    padding-left: 15px;

    &.vertical {
      grid-template-columns: repeat(auto-fit, minmax(159px, 159px));

      &.large {
        grid-template-columns: repeat(auto-fit, minmax(300px, 460px));
      }

      &.medium {
        grid-template-columns: repeat(auto-fit, minmax(300px, 340px));
      }

      &.small {
        grid-template-columns: repeat(auto-fit, minmax(220px, 220px));
      }
    }

    &.circle {
      grid-template-columns: repeat(auto-fit, minmax(135px, 135px));
    }

    &.horizontal {
      grid-template-columns: repeat(auto-fit, minmax(260px, 260px));

      &.large {
        grid-template-columns: repeat(auto-fit, minmax(300px, 460px));
      }

      &.medium {
        grid-template-columns: repeat(auto-fit, minmax(300px, 340px));
      }

      &.small {
        grid-template-columns: repeat(auto-fit, minmax(220px, 220px));
      }
    }
  }

  .store-item {
    list-style-type: none;
    margin-left: 20px;
    margin-bottom: 50px;
    width: 135px;
  }

  .pagination-container {
    max-width: 500px;
    margin: 0 auto 45px auto;
  }

  .loader-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>

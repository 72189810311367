import Auth from '@/service/authService';

export const performProductsMinPrice = (products) => {
  const { pricing_package } = Auth.get('user');

  if (!pricing_package) {
    return products;
  }

  return products.map((i) => ({
    ...i,
    price: i.pricing[pricing_package] || i.price,
  }));
};

export const updateVariantPriceFromPricelist = (variant, pricelist) => {
  const variantPrice = getVariantPriceFromPricelist(variant, pricelist);

  return {
    ...variant,
    price: variantPrice || variant.price,
  };
};

export const getVariantPriceFromPricelist = (variant, pricelist) => {
  const { pricing_package } = Auth.get('user');

  if (!pricing_package || !pricelist) {
    return null;
  }

  const variantId = variant.id.substring('gid://shopify/ProductVariant/'.length);
  const variantPricelist = pricelist[variantId].pricing;

  if (!variantPricelist) {
    return null;
  }

  return variantPricelist[pricing_package] || null;
}

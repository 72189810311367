/**
 * Finds match between string and some string from array.
 *
 * @param {string} url
 * @param {Array<string>} stringsToMatch
 * @returns {boolean} is string matched
 */
export function matchString(string, stringsToMatch) {
  return stringsToMatch.some((el) => string.includes(el));
}

export const SMALL_PRODUCTS_CAROUSEL_ITEMS_CONFIG = {
  slidesPerView: 5,
  spaceBetween: 16,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    3200: {
      slidesPerView: 13,
      spaceBetween: 24,
    },
    2700: {
      slidesPerView: 11,
      spaceBetween: 24,
    },
    2400: {
      slidesPerView: 10,
      spaceBetween: 24,
    },
    2200: {
      slidesPerView: 9,
      spaceBetween: 24,
    },
    1900: {
      slidesPerView: 8,
      spaceBetween: 24,
    },
    1700: {
      slidesPerView: 7,
      spaceBetween: 24,
    },
    1500: {
      slidesPerView: 6,
      spaceBetween: 24,
    },
    1200: {
      slidesPerView: 5,
      spaceBetween: 24,
    },
    1000: {
      slidesPerView: 4,
      spaceBetween: 24,
    },
    800: {
      slidesPerView: 3,
      spaceBetween: 24,
    },
    500: {
      slidesPerView: 2,
      spaceBetween: 24,
    },
    300: {
      slidesPerView: 1,
      spaceBetween: 24,
    },
  },
  preloadImages: false,
  watchSlidesProgress: true,
  lazy: true,
};

export const MEDIUM_PRODUCTS_CAROUSEL_ITEMS_CONFIG = {
  slidesPerView: 4,
  spaceBetween: 10,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    3200: {
      slidesPerView: 9,
      spaceBetween: 24,
    },
    2750: {
      slidesPerView: 8,
      spaceBetween: 24,
    },
    2500: {
      slidesPerView: 7,
      spaceBetween: 24,
    },
    2150: {
      slidesPerView: 6,
      spaceBetween: 24,
    },
    1800: {
      slidesPerView: 5,
      spaceBetween: 24,
    },
    1450: {
      slidesPerView: 4,
      spaceBetween: 24,
    },
    1100: {
      slidesPerView: 3,
      spaceBetween: 24,
    },
    750: {
      slidesPerView: 2,
      spaceBetween: 24,
    },
    300: {
      slidesPerView: 1,
      spaceBetween: 24,
    },
  },
  preloadImages: false,
  watchSlidesProgress: true,
  lazy: true,
};

export const LARGE_PRODUCTS_CAROUSEL_ITEMS_CONFIG = {
  slidesPerView: 3,
  spaceBetween: 5,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    2900: {
      slidesPerView: 6,
      spaceBetween: 24,
    },
    2400: {
      slidesPerView: 5,
      spaceBetween: 24,
    },
    1900: {
      slidesPerView: 4,
      spaceBetween: 24,
    },
    1400: {
      slidesPerView: 3,
      spaceBetween: 24,
    },
    1000: {
      slidesPerView: 2,
      spaceBetween: 24,
    },
    300: {
      slidesPerView: 1,
      spaceBetween: 24,
    },
  },
  preloadImages: false,
  watchSlidesProgress: true,
  lazy: true,
};
